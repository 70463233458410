module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dayna.zone","short_name":"dayna.zone","description":"The application does cool things and makes your life better.","lang":"en","start_url":"/","background_color":"#1c1e2d","theme_color":"#1c1e2d","display":"minimal-ui","icon":"src/images/dayna-zone-icon.png","icon_options":{"purpose":"maskable any"},"legacy":false},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"preserveStackingContext":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
